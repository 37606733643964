import DoneIcon from '@mui/icons-material/Done';
import { Container, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, styled, Typography } from '@mui/material';
import React from 'react';

function Home() {
    const Item = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
    }));

    const ItemCenter = styled(Paper)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    return (
        <React.Fragment>
            <Container component="main">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <ItemCenter>
                            <Typography variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                                Biuro Usług Budowlanych F-BUD s.c. jest firmą rodzinną działającą nieprzerwanie od 1993r.
                            </Typography>
                            <Typography variant="subtitle1" component="div">
                                Wieloletnie doświadczenie pozwala podejmować nam najbardziej nietypowe wyzwania projektowe.
                            </Typography>
                        </ItemCenter>
                    </Grid>
                    <Grid item xs={6}>
                        <Item>
                            <Typography variant="subtitle1" component="div">
                                Zajmujemy się branżą konstrukcyjną w każdym zakresie konstrukcji:
                            </Typography>
                            <List dense={true}>
                                <ListItem>
                                    <ListItemIcon sx={{ fontSize: 10, minWidth: 0, minHeight: 0 }}>
                                        <DoneIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{ my: 0 }}
                                        primary="murowanych"
                                        primaryTypographyProps={{
                                            fontSize: 13,
                                            fontWeight: 'medium',
                                            lineHeight: '20px',
                                            mb: '2px',
                                        }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={{ fontSize: 10, minWidth: 0, minHeight: 0 }}>
                                        <DoneIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="drewnianych"
                                        primaryTypographyProps={{
                                            fontSize: 13,
                                            fontWeight: 'medium',
                                            lineHeight: '20px',
                                            mb: '2px',
                                        }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={{ fontSize: 10, minWidth: 0, minHeight: 0 }}>
                                        <DoneIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="stalowych"
                                        primaryTypographyProps={{
                                            fontSize: 13,
                                            fontWeight: 'medium',
                                            lineHeight: '20px',
                                            mb: '2px',
                                        }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={{ fontSize: 10, minWidth: 0, minHeight: 0 }}>
                                        <DoneIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="żelbetowych"
                                        primaryTypographyProps={{
                                            fontSize: 13,
                                            fontWeight: 'medium',
                                            lineHeight: '20px',
                                            mb: '2px',
                                        }}
                                    />
                                </ListItem>
                            </List>
                        </Item>
                    </Grid>
                    <Grid item xs={6}>
                        <Item>
                            <Typography variant="subtitle1" component="div">
                                Zapewniamy profesjonalne przygotowanie dokumentacji technicznej we wszystkich stadiach projektowania:
                            </Typography>
                            <List dense={true}>
                                <ListItem>
                                    <ListItemIcon sx={{ fontSize: 10, minWidth: 0, minHeight: 0 }}>
                                        <DoneIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{ my: 0 }}
                                        primary="koncepcja"
                                        primaryTypographyProps={{
                                            fontSize: 13,
                                            fontWeight: 'medium',
                                            lineHeight: '20px',
                                            mb: '2px',
                                        }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={{ fontSize: 10, minWidth: 0, minHeight: 0 }}>
                                        <DoneIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="projekt budowlany"
                                        primaryTypographyProps={{
                                            fontSize: 13,
                                            fontWeight: 'medium',
                                            lineHeight: '20px',
                                            mb: '2px',
                                        }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={{ fontSize: 10, minWidth: 0, minHeight: 0 }}>
                                        <DoneIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="projekt wykonawczy"
                                        primaryTypographyProps={{
                                            fontSize: 13,
                                            fontWeight: 'medium',
                                            lineHeight: '20px',
                                            mb: '2px',
                                        }}
                                    />
                                </ListItem>
                            </List>
                        </Item>
                    </Grid>
                    <Grid item xs={12}>
                        <Item>
                            <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold' }}>
                                Projektujemy w oparciu o licencjonowane oprogramowanie:
                            </Typography>
                            <List dense={true}>
                                <ListItem>
                                    <ListItemIcon sx={{ fontSize: 10, minWidth: 0, minHeight: 0 }}>
                                        <DoneIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{ my: 0 }}
                                        primary="AutoCAD"
                                        primaryTypographyProps={{
                                            fontSize: 13,
                                            fontWeight: 'medium',
                                            lineHeight: '20px',
                                            mb: '2px',
                                        }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={{ fontSize: 10, minWidth: 0, minHeight: 0 }}>
                                        <DoneIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="ArchiCAD"
                                        primaryTypographyProps={{
                                            fontSize: 13,
                                            fontWeight: 'medium',
                                            lineHeight: '20px',
                                            mb: '2px',
                                        }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={{ fontSize: 10, minWidth: 0, minHeight: 0 }}>
                                        <DoneIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="Robot Structural Analysis"
                                        primaryTypographyProps={{
                                            fontSize: 13,
                                            fontWeight: 'medium',
                                            lineHeight: '20px',
                                            mb: '2px',
                                        }}
                                    />
                                </ListItem>
                            </List>
                        </Item>
                    </Grid>
                    <Grid item xs={6}>
                        <Item>
                            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                                Zakres działalności
                            </Typography>
                            <List dense={true}>
                                <ListItem>
                                    <ListItemIcon sx={{ fontSize: 10, minWidth: 0, minHeight: 0 }}>
                                        <DoneIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{ my: 0 }}
                                        primary="projekty branży konstrukcyjnej"
                                        primaryTypographyProps={{
                                            fontSize: 13,
                                            fontWeight: 'medium',
                                            lineHeight: '20px',
                                            mb: '2px',
                                        }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={{ fontSize: 10, minWidth: 0, minHeight: 0 }}>
                                        <DoneIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="ekspertyzy techniczne"
                                        primaryTypographyProps={{
                                            fontSize: 13,
                                            fontWeight: 'medium',
                                            lineHeight: '20px',
                                            mb: '2px',
                                        }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={{ fontSize: 10, minWidth: 0, minHeight: 0 }}>
                                        <DoneIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="nadzory budowlane"
                                        primaryTypographyProps={{
                                            fontSize: 13,
                                            fontWeight: 'medium',
                                            lineHeight: '20px',
                                            mb: '2px',
                                        }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={{ fontSize: 10, minWidth: 0, minHeight: 0 }}>
                                        <DoneIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{ my: 0 }}
                                        primary="inwentaryzacje"
                                        primaryTypographyProps={{
                                            fontSize: 13,
                                            fontWeight: 'medium',
                                            lineHeight: '20px',
                                            mb: '2px',
                                        }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={{ fontSize: 10, minWidth: 0, minHeight: 0 }}>
                                        <DoneIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{ my: 0 }}
                                        primary="projekty podziału budynków"
                                        primaryTypographyProps={{
                                            fontSize: 13,
                                            fontWeight: 'medium',
                                            lineHeight: '20px',
                                            mb: '2px',
                                        }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={{ fontSize: 10, minWidth: 0, minHeight: 0 }}>
                                        <DoneIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{ my: 0 }}
                                        primary="certyfikaty energetyczne"
                                        primaryTypographyProps={{
                                            fontSize: 13,
                                            fontWeight: 'medium',
                                            lineHeight: '20px',
                                            mb: '2px',
                                        }}
                                    />
                                </ListItem>
                            </List>
                        </Item>
                    </Grid>
                    <Grid item xs={6}>
                        <Item>
                            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                                Zajmujemy się projektowaniem następujących obiektów:
                            </Typography>
                            <List dense={true}>
                                <ListItem>
                                    <ListItemIcon sx={{ fontSize: 10, minWidth: 0, minHeight: 0 }}>
                                        <DoneIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{ my: 0 }}
                                        primary="budynki mieszkalne"
                                        primaryTypographyProps={{
                                            fontSize: 13,
                                            fontWeight: 'medium',
                                            lineHeight: '20px',
                                            mb: '2px',
                                        }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={{ fontSize: 10, minWidth: 0, minHeight: 0 }}>
                                        <DoneIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="budynki użyteczności publicznej"
                                        primaryTypographyProps={{
                                            fontSize: 13,
                                            fontWeight: 'medium',
                                            lineHeight: '20px',
                                            mb: '2px',
                                        }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={{ fontSize: 10, minWidth: 0, minHeight: 0 }}>
                                        <DoneIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary="budynki handlowe i usługowe"
                                        primaryTypographyProps={{
                                            fontSize: 13,
                                            fontWeight: 'medium',
                                            lineHeight: '20px',
                                            mb: '2px',
                                        }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={{ fontSize: 10, minWidth: 0, minHeight: 0 }}>
                                        <DoneIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{ my: 0 }}
                                        primary="budynki sakralne"
                                        primaryTypographyProps={{
                                            fontSize: 13,
                                            fontWeight: 'medium',
                                            lineHeight: '20px',
                                            mb: '2px',
                                        }}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon sx={{ fontSize: 10, minWidth: 0, minHeight: 0 }}>
                                        <DoneIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{ my: 0 }}
                                        primary="obiekty przemysłowe"
                                        primaryTypographyProps={{
                                            fontSize: 13,
                                            fontWeight: 'medium',
                                            lineHeight: '20px',
                                            mb: '2px',
                                        }}
                                    />
                                </ListItem>
                            </List>
                        </Item>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
}

export default Home;