import React, { useCallback, useState } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { Container } from "@mui/material";

const photos = [
    {
        src: "/gallery/K_1.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/K_2.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/K_3.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/K_4.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/K_5.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/K_6.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/L_1.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/L_2.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/L_3.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/L_4.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/L_5.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/L_6.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/L_7.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/L_8.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/L_9.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/L_10.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/L_11.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/L_12.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/L_13.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/M_1.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/M_2.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/M_3.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/PKS_1.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/PKS_2.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/PKS_3.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/PKS_4.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/PKS_5.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/PKS_6.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/PKS_7.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/PKS_8.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/PROTRONIC_1.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/PROTRONIC_2.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/PROTRONIC_3.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/PROTRONIC_4.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/PROTRONIC_5.JPG",
        width: 4,
        height: 4,
        title: "",
    },
    {
        src: "/gallery/PROTRONIC_6.JPG",
        width: 4,
        height: 4,
        title: "",
    }
]

function Galeria() {
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };
    return (
        <React.Fragment>
            <Container component="main">
                <Gallery photos={photos} onClick={openLightbox} />
                <ModalGateway>
                    {viewerIsOpen ? (
                        <Modal onClose={closeLightbox}>
                            <Carousel
                                currentIndex={currentImage}
                                views={photos.map(x => ({
                                    width: x.width,
                                    height: x.height,
                                    source: x.src,
                                    srcset: x.src,
                                    caption: x.title
                                }))}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </Container>
        </React.Fragment>
    );
}

export default Galeria;