import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { AppBar, BottomNavigation, BottomNavigationAction, Button, Container, FormControl, Grid, InputLabel, Link, MenuItem, Modal, Paper, Select, SelectChangeEvent, Stack, Step, StepContent, StepLabel, Stepper, TextField, Toolbar } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useReducer, useState } from 'react';
import { z } from "zod";


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    height: "80%",
    width: '80%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    overflow: 'scroll'
};

let steps: number[] = [1, 2, 3, 4];

function labelFactory(step: number): string {
    switch (step) {
        case 0: {
            return 'Materiał';
        }
        case 1: {
            return 'Kolor';
        }
        case 2: {
            return '';
        }
        case 3: {
            return 'Wymiary'
        }
    }
    return '';
}

function Konfigurator() {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setEditorIsOpen(true);
    const handleClose = () => setEditorIsOpen(false);
    const [editorIsOpen, setEditorIsOpen] = useState(false);

    const [activeStep, setActiveStep] = React.useState(0);

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const [product, setProduct] = React.useState('');

    const handleProductChange = (event: SelectChangeEvent) => {
        setProduct(event.target.value as string)
    };

    let ca: { window_id: number, window_product: string, window_material: string, window_color: string, window_type_id: number, window_height: string, window_width: string }[] = [];
    const storageCards = localStorage.getItem('cards');
    if (storageCards !== null && storageCards !== undefined && storageCards !== '') {
        ca = JSON.parse(storageCards);
    }
    const [cards, setCards] = React.useState(ca);

    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const handleDelete = (window_id: number) => {
        let index = cards.findIndex(d => d.window_id === window_id);
        cards.splice(index, 1);
        localStorage.setItem('cards', JSON.stringify(cards))
        forceUpdate()
    }

    const [material, setMaterial] = React.useState('');

    const handleMaterialChange = (event: SelectChangeEvent) => {
        setMaterial(event.target.value as string)
    };

    const [color, setColor] = React.useState('');

    const handleColorChange = (event: SelectChangeEvent) => {
        setColor(event.target.value as string)
    };

    const [type, setType] = React.useState(0);

    const handleTypeChange = (event: React.MouseEvent<HTMLElement>) => {
        const element = event.currentTarget as HTMLInputElement
        element.style.filter = 'grayscale(100%)'
        setType(parseInt(element.id))
        handleNext();
    }

    const handleNext = () => {
        if (activeStep === steps.length - 1) {
            console.log(activeStep)
            cards.push({
                window_id: Date.now(),
                window_product: product,
                window_material: material,
                window_color: color,
                window_type_id: type,
                window_height: windowHeight,
                window_width: windowWidth
            })
            setActiveStep(0)
            setProduct('')
            setMaterial('')
            setColor('')
            setWindowHeight('')
            setWindowWidth('')
            setType(0)
            setValidationErrors(true)
            setOpen(false)
            handleClose()
            localStorage.setItem('cards', JSON.stringify(cards))
            return
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const windowdDimensionsValidator = z.number().positive().int();

    const [windowHeight, setWindowHeight] = React.useState('');
    const [windowHeightError, setWindowHeightError] = React.useState('');

    const hanldeHeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value;
        setWindowHeight(val);
        const pars = windowdDimensionsValidator.safeParse(parseInt(val));
        if (pars.success) {
            setWindowHeightError('');
        } else {
            setWindowHeightError('Wpisz poprawną wysokość');
        }
        setValidationErrors(!windowdDimensionsValidator.safeParse(parseInt(windowHeight)).success || !windowdDimensionsValidator.safeParse(parseInt(windowWidth)).success)
    }

    const [windowWidth, setWindowWidth] = React.useState('');
    const [windowWidthError, setWindowWidthError] = React.useState('');

    const hanldeWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val = event.target.value;
        setWindowWidth(val);
        const pars = windowdDimensionsValidator.safeParse(parseInt(val));
        if (pars.success) {
            setWindowWidthError('');
        } else {
            setWindowWidthError('Wpisz poprawną wysokość');
        }
        setValidationErrors(!windowdDimensionsValidator.safeParse(parseInt(windowHeight)).success || !windowdDimensionsValidator.safeParse(parseInt(windowWidth)).success)
    }

    const [validationErrors, setValidationErrors] = React.useState(true);

    const getContent = (step: number) => {
        switch (step) {
            case 0: {
                // if (product !== 'Bramy') {
                return <FormControl fullWidth>
                    <InputLabel id="material-label">Wybierz Materiał</InputLabel>
                    <Select
                        labelId="material-label"
                        id="material-select"
                        value={material}
                        label="Wybierz Materiał"
                        onChange={handleMaterialChange}
                        onClose={handleNext}
                    >
                        <MenuItem value={'PVC'}>PVC</MenuItem>
                        <MenuItem value={'Aluminium'}>Aluminium</MenuItem>
                        <MenuItem value={'Drewno'}>Drewno</MenuItem>
                    </Select>
                </FormControl>
            }
            case 1: {
                return <FormControl fullWidth>
                    <InputLabel id="color-label">Wybierz Kolor</InputLabel>
                    <Select
                        labelId="color-label"
                        id="color-select"
                        value={color}
                        label="Wybierz Kolor"
                        onChange={handleColorChange}
                        onClose={handleNext}
                    >
                        <MenuItem value={'Biały'}>Biały</MenuItem>
                        <MenuItem value={'Czarny'}>Czarny</MenuItem>
                        <MenuItem value={'Drewno'}>Drewno</MenuItem>
                    </Select>
                </FormControl>;
            }
            case 2: {
                return <React.Fragment>
                    <Grid container spacing={0}>
                        {itemData.map((item) => (
                            <Grid item xs={4} sx={{ marginBottom: '10px' }}>
                                <Link href='#'>
                                    <img width="20%" height="75%"
                                        src={`${item.img}`}
                                        srcSet={`${item.img}`}
                                        alt={item.title}
                                        loading="eager"
                                        id={`${item.typeId}`}
                                        onClick={handleTypeChange}
                                    />
                                </Link>
                                <Typography variant='body2'>
                                    {item.title}
                                </Typography>
                            </Grid>
                        ))}
                    </Grid>
                </React.Fragment>
            } case 3: {
                return <React.Fragment>
                    <TextField
                        error={windowHeightError === '' ? false : true}
                        id="height_field"
                        label="Wpisz wysokość w cm"
                        type="number"
                        defaultValue=""
                        helperText="Wpisz poprawną wysokość"
                        variant="filled"
                        onChange={hanldeHeightChange} />
                    <TextField
                        error={windowWidthError === '' ? false : true}
                        id="width_field"
                        label="Wpisz szerokość w cm"
                        defaultValue=""
                        helperText="Wpisz poprawną szerokość"
                        type="number"
                        variant="filled"
                        onChange={hanldeWidthChange} />
                </React.Fragment>
            }
        }
        return '';
    }

    return (
        <React.Fragment>
            <Container maxWidth="sm" component="main">
                {!editorIsOpen ? (
                    <Stack>
                        {cards.map(card => (
                            <Card sx={{ display: 'flex', margin: '5px' }}>
                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <CardContent>
                                        <Typography variant="subtitle2" component="div">
                                            Materiał: {card.window_material}
                                        </Typography>
                                        <Typography variant="subtitle2" component="div">
                                            Kolor: {card.window_color}
                                        </Typography>
                                        <Typography variant="subtitle2" component="div">
                                            Wysokość: {card.window_height} mm
                                        </Typography>
                                        <Typography variant="subtitle2" component="div">
                                            Szerokość: {card.window_width} mm
                                        </Typography>
                                        <img
                                            src={`${itemData.find(e => e.typeId === card.window_type_id)?.img}`}
                                            srcSet={`${itemData.find(e => e.typeId === card.window_type_id)?.img}`}
                                            alt={itemData.find(e => e.typeId === card.window_type_id)?.title}
                                            loading="lazy"
                                            width='100px'
                                            height='100px'
                                        />
                                        {/* <Button variant="outlined" startIcon={<DeleteIcon />}>
                                        Edytuj
                                    </Button> */}
                                        <br />
                                        <Button variant="outlined" startIcon={<DeleteIcon />} onClick={e => handleDelete(card.window_id)}>
                                            Usuń Okno
                                        </Button>
                                    </CardContent>
                                </Box>
                            </Card>
                        ))}
                        <Card sx={{ alignItems: 'center', margin: '5px' }}>
                            <Box sx={{ alignItems: 'center' }}>
                                <CardContent sx={{ alignItems: 'center' }}>
                                    <Button variant="outlined" startIcon={<AddIcon />} onClick={handleOpen}>
                                        Dodaj Okno
                                    </Button>
                                </CardContent>
                            </Box>
                        </Card>
                    </Stack>) : (<Box sx={style}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((step, index) => (
                                <Step key={step}>
                                    <StepLabel>
                                    </StepLabel>
                                    <StepContent>
                                        <Typography sx={{ mb: 2 }}>{labelFactory(activeStep)}</Typography>
                                        <Box sx={{ mb: 2 }}>
                                            <div>
                                                {getContent(activeStep)}
                                                {activeStep === steps.length - 1 && <Button
                                                    disabled={validationErrors}
                                                    variant="contained"
                                                    onClick={handleNext}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    Dodaj
                                                </Button>}
                                                <Button
                                                    disabled={index === 0}
                                                    onClick={handleBack}
                                                    sx={{ mt: 1, mr: 1 }}
                                                >
                                                    Cofnij
                                                </Button>
                                            </div>
                                        </Box>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>)}
            </Container>
            {!editorIsOpen ?
                <AppBar position="sticky" color="transparent">
                    <Container maxWidth="md">
                        <Typography variant="h6" align='center'>
                            Typ i nazwa operacji:
                        </Typography>
                        <Typography variant="body2" align='center'>
                            Operacja dotyczy rozwoju przedsiębiorstwa w zakresie usług handlu detalicznego
                            zewnętrznej stolarki otworowej . Operacja ma charakter inwestycyjny polegający na
                            rozbudowie budynku mieszkalno-usługowego o część usługową.
                        </Typography>
                        <Typography variant="h6" align='center'>
                            Cel operacji:
                        </Typography>
                        <Typography variant="body2" align='center'>
                            Rozbudowa budynku mieszkalno-usługowego o część usługową oraz zatrudnienie
                            pracownika do dnia złożenia wniosku o płatność
                        </Typography>
                        <Typography variant="h6" align='center'>
                            Wynik operacji:
                        </Typography>
                        <Typography variant="body2" align='center'>

                            Zatrudnienie pracownika na 1 pełny etat średnioroczny
                        </Typography>
                        <Typography variant="h6" align='center'>
                            Nazwa działania PROW 2014-2020:
                        </Typography>
                        <Typography variant="body2" align='center'>
                            „Wsparcie na wdrażanie operacji w ramach strategii rozwoju lokalnego kierowanego przez
                            społeczność”, t.j. wspieranie lokalnego rozwoju na obszarach wiejskich w ramach działania
                            „Wsparcie dla rozwoju lokalnego w ramach inicjatywy LEADER” objętego Programem w
                            zakresie rozwijania działalności gospodarczej.
                        </Typography>
                        <Toolbar sx={{justifyContent: 'center'}}>
                            <img
                                src="./logos/eu.svg"
                                alt=""
                                style={{ height: '100px', margin: '5px' }}
                            />
                            <img
                                src="./logos/Leader.png"
                                alt=""
                                style={{ height: '100px', margin: '5px' }}
                            />
                            <img
                                src="./logos/slgd.jpg"
                                alt=""
                                style={{ height: '100px', margin: '5px' }}
                            />
                            <img
                                src="./logos/prow.svg"
                                alt=""
                                style={{ height: '100px', margin: '5px' }}
                            />
                        </Toolbar>
                    </Container>
                </AppBar>
                : null
            }
        </React.Fragment >
    );
}


const itemData = [
    {
        img: '/img/pvc_1.jpg',
        title: 'Okno stałe w ramie',
        typeId: 1
    },
    {
        img: '/img/pvc_2.jpg',
        title: 'Okno 1-skrzydłowe, rozwierno-uchylne',
        typeId: 2
    },
    {
        img: '/img/pvc_3.jpg',
        title: 'Okno 2-skrzydłowe, rozwierno-uchylne/rozwierno-uchylne',
        typeId: 3
    },
    {
        img: '/img/pvc_4.jpg',
        title: 'Okno 2-skrzydłowe, rozwierno-uchylne/rozwierne, ruchomy słupek',
        typeId: 4
    },
    {
        img: '/img/pvc_5.jpg',
        title: 'Okno 3-skrzydłowe rozwierno-uchylne, rozwierno-uchylne, rozwierno-uchylne, stałe słupki',
        typeId: 5
    },
    {
        img: '/img/pvc_6.jpg',
        title: 'Drzwi balkonowe, 1-skrzydłowe, rozwierno-uchylne',
        typeId: 6
    },
    {
        img: '/img/pvc_7.jpg',
        title: 'Drzwi balkonowe, 2-skrzydłowe, rozwierno-uchylne/rozwierne, ruchomy słupek',
        typeId: 7
    }
];

export default Konfigurator;