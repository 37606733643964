import { AppBar, CssBaseline, Link, Toolbar, Typography } from '@mui/material';
import { createTheme } from '@mui/system';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import './App.css';
import Galeria from './Galeria';
import Home from './Home';
import Konfigurator from './Konfigurator';
import Kontakt from './Kontakt';


function Copyright() {
  return (
    <div className='footer'>
      <Typography variant="body2" color="textSecondary" align="center">
        F-bud<br />
        Ludźmierz ul.Na Brzegu 2<br />
        18 266 49 43<br />
      </Typography>
    </div>
  );
}

const theme = createTheme()

function WelcomePage() {
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="static" color="default" elevation={0} sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <Typography variant="h6">
            <a href="/">
              <img src="./logo.png" alt="logo" height={50} />
            </a>
          </Typography>
          <Link variant="h6" color="inherit" href="/" sx={{ flexGrow: 1 }}>
            F-BUD
          </Link>
          <nav>
            <Link variant="button" color="textPrimary" underline="hover" href="/" sx={{ margin: theme.spacing(1, 1.5) }}>
              O Nas
            </Link>
            <Link variant="button" color="textPrimary" underline="hover" href="/konfigurator" sx={{ margin: theme.spacing(1, 1.5) }}>
              Konfigurator okien
            </Link>
            <Link variant="button" color="textPrimary" underline="hover" href="/galeria" sx={{ margin: theme.spacing(1, 1.5) }}>
              Galeria
            </Link>
            <Link variant="button" color="textPrimary" underline="hover" href="/kontakt" sx={{ margin: theme.spacing(1, 1.5) }}>
              Kontakt
            </Link>
          </nav>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
}

export default function App() {
  return (
    <React.Fragment>
      <div>
        <WelcomePage />
        <Switch>
          <Route exact path="/" component={Home} />
          {/* <Route exact path="/uslugi" component={Uslugi} /> */}
          <Route exact path="/konfigurator" component={Konfigurator} />
          <Route exact path="/galeria" component={Galeria} />
          <Route exact path="/kontakt" component={Kontakt} />
          <Redirect from="/" to="/" />
        </Switch>
      </div>
      {/* <Copyright></Copyright> */}
    </React.Fragment>
  )
}