import { Container, Typography } from '@mui/material';
import React from 'react';
import { compose, withProps } from "recompose"
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"

const MyMapComponent = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDXckyCSU3I-UcqP_tOqWdeuYvK21-9utM",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) =>
  <GoogleMap
    defaultZoom={12}
    defaultCenter={{ lat: 49.459, lng: 19.989 }}
  >
    <Marker position={{ lat: 49.459, lng: 19.989 }} />
  </GoogleMap>
);


function Kontakt() {
    return (
        <React.Fragment>
            <Container component="main">
                <Typography variant="h5" align="center" color="textPrimary" gutterBottom>
                    Biuro Usług Budowlanych F-BUD s.c. Iwona i Paweł Fryźlewicz
                </Typography>
                <Typography variant="body1" align="center" color="textPrimary" gutterBottom>
                    Adres: Na Brzegu 2 Ludźmierz <br/>
                    Email: <a href="mailto:biuro@f-bud.pl">biuro@f-bud.pl</a> <br/>
                    Telefon: <a href="tel:+48182664943">+48 18 266 49 43</a> <br/>
                </Typography>
                <MyMapComponent/>
            </Container>
        </React.Fragment>
    );
}

export default Kontakt;